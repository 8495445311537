/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import { StaticImage } from "gatsby-plugin-image";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import LayoutHome from "../layout/LayoutHome";
import { eventCallbackTriggers } from "../templates/wppage";

const AtlantaHeartNotesSponsorship = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "Atlanta Heart Notes Sponsorship, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) =>
      eventCallbackTriggers("atlanta_heart_notes_sponsorship,_USA", event),
    []
  );
  const renderContentHeader = () => (
    <Grid
      columns={3}
      sx={{
        backgroundColor: "#eaf9fa",
      }}
    >
      <Box
        sx={{
          backgroundColor: "#eaf9fa",
        }}
      >
        <StaticImage
          src="../../static/imgs/heartnotes-sidebg.png"
          alt="Logo"
          sx={{
            marginTop: "174px",
          }}
        />
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <div
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          {" "}
          <h2>
            <b>Heartnotes 2019</b>
            <br />
            HELP our youth
          </h2>
        </div>

        <p sx={{ color: "#1ca0f2" }}>
          Inspirational Talk <span> | </span> Gourmet Lunch <span> | </span>{" "}
          Make a Difference
        </p>
        <h4>
          <b>June 2nd,</b>
          <br />
          2019
        </h4>
        <span sx={{ color: "#999" }}>Signature Ballroom, Suwanee, GA</span>
        <div
          className="test"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <StaticImage
            src="../../static/imgs/heart-notes-logo-2019.png"
            alt="Logo"
          />
        </div>
      </Box>
      <Box>
        <StaticImage
          src="../../static/imgs/heartnotes-2019-bg.png"
          alt="Logo"
          sx={{}}
        />
      </Box>
    </Grid>
  );

  return (
    <LayoutHome
      contentHeader={renderContentHeader()}
      seoTitle="Atlanta Heart Notes Sponsorship - Donations"
    >
      <Grid
        columns={1}
        sx={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box
          sx={{
            paddingTop: "20px",
          }}
        >
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(17, "Donate Now")}
          />
        </Box>
        <Box sx={{ color: "#2d3550" }}>
          <div>
            <h1
              sx={{
                color: "#2d3550",
                fontSize: "1.5em",
                fontWeight: "600",
                marginTop: "20px",
              }}
            >
              Heartfulness Institute
            </h1>
          </div>
          <div>
            <p style={{ textAlign: "justify" }}>
              Since 1945, Heartfulness meditation practices have spread to
              millions of people worldwide. These practices are offered free of
              charge to people fifteen years of age and above, and Heartfulness
              Relaxation is offered to children from five years of age. Today
              there are thousands of certified Heartfulness volunteer trainers
              serving people in over 130 countries. They are supported by
              Kamlesh Patel, who is also known as Daaji. You can learn more
              about Daaji at{" "}
              <a href="https://www.daaji.org/" target="_blank" rel="noreferrer">
                daaji.org{" "}
              </a>
              .
            </p>
            <div>
              <h5
                sx={{
                  color: "#2d3550",
                  fontSize: "1em",
                  fontWeight: "600",
                }}
              >
                Heartfulness organizations manage various programs and
                facilities worldwide, including:
              </h5>
              <ul>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Scholarships for students from all continents to
                    attend advanced training programs
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Cutting-edge research on the effects of meditation
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; ‘The Heartfulness Way’ programs on values and life
                    skills for teachers and students in schools
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Specialized programs for professions, e.g.
                    accredited Continuous Medical Education units for medical
                    professionals, programs for legal professionals
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Programs in colleges, universities, corporate and
                    government organizations
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Programs for villagers and tribal communities
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Free medical clinics
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Ashrams, meditation centers, research facilities
                    and retreat centers
                  </p>
                </li>
              </ul>
              <Box>
                <Grid columns={[1, 1, 3, null]}>
                  <Box>
                    <StaticImage
                      src="../../static/imgs/hfn-int-img11.jpg"
                      alt="Logo"
                    />
                  </Box>
                  <Box>
                    <StaticImage
                      src="../../static/imgs/hfn-int-med-img2.jpg"
                      alt="Logo"
                    />
                  </Box>
                  <Box>
                    <StaticImage
                      src="../../static/imgs/hfn-int-med-img3.jpg"
                      alt="Logo"
                    />
                  </Box>
                </Grid>
                <div>
                  <p sx={{ marginTop: "0px", textAlign: "justify" }}>
                    Heartfulness Institute Activities in Metro Atlanta
                  </p>
                </div>
              </Box>
              <ul>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Free weekly group Meditations in Cumming, Roswell,
                    Alpharetta, Marietta, Sandy Springs, and Norcross
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Over 50 skilled trainers in metro Atlanta; all
                    Meditation sessions provided free of charge
                  </p>
                </li>
                <li>
                  <p sx={{ marginBottom: "0.5rem", textAlign: "justify" }}>
                    &#x2022; Free Relaxation and Meditation programs at
                    libraries, parks, neighborhood clubhouses, corporates,
                    schools and temples.
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </Box>
      </Grid>
    </LayoutHome>
  );
};

export default AtlantaHeartNotesSponsorship;
